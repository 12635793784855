import { combineReducers } from 'redux'
import userReducer from './loginSlice';
import libraryReducer from './librarySlice';
import consumerReducer from './consumerSlice';
import lenscatalogReducer from './lenscatalogSlice';
import prescriptionReducer from './prescriptionSlice';
import demoReducer from './demoSlice';
import frameReducer from './frameSlice';
import checkoutReducer from './checkoutSlice';
import salesReducer from './salesSlice';
import invoiceReducer from './invoiceSlice';
import exchangeReducer from './exchangeSlice';
import returnsReducer from './returnsSlice';
import reminderReducer from './reminderSlice';
import storeLoginReducer from './storeLoginSlice';
import cashdepositReducer from './cashdepositSlice';
import transactionsReducer from './transactionsSlice';
import b2bInvoiceReducer from './b2bInvoiceSlice';
import { barcodeScannerReducer as barcodeScanner } from 'react-usb-barcode-scanner';

import {
    reportReducer,
    reportReducerv2
} from './reportSlice';

export default combineReducers({
    userReducer,
    libraryReducer,
    consumerReducer,
    lenscatalogReducer,
    prescriptionReducer,
    demoReducer,
    frameReducer,
    checkoutReducer,
    salesReducer,
    invoiceReducer,
    exchangeReducer,
    returnsReducer,
    reminderReducer,
    storeLoginReducer,
    cashdepositReducer,
    transactionsReducer,
    b2bInvoiceReducer,
    reportReducer,
    reportReducerv2,
    barcodeScanner
});