import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  getProductListData,
  setProductListData,
  getProductByBarcode,
  setProductByBarcode,
  getGlassDropDowns,
  setGlassDropDowns,
  getBrandDropDowns,
  setBrandDropDowns
} from "../reducers/librarySlice";
import { API_URL } from "../../utils/constants";
import _ from "lodash";

function fetchProductsDataAPI(payload) {
  const storeId = localStorage.getItem("storeId");
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const { page , filter, search } = payload;
  const apiURL = `${API_URL}/client/inventory/fetchwarehouseinventory`;
  const products = axios
    .get(apiURL, {
       params: {
        limit: 20,
        page: page,
        storeId: storeId,
        search: search,
        filter: filter || {},
        sort: {
           "_id.Brand": 1,
           "_id.ColorCode": 1,
           "_id.SizeActual": 1 
        },
      },
      headers: headers
    })
    .then((response) => response);
  return products;
}
export function* fetchProductsData(action) {
  try {
      const { payload } = action;
      const response = yield call(fetchProductsDataAPI, payload);
      yield put(setProductListData(response.data.data));
  } catch (error) {
    //yield put(setReponseStatus(error.response.status));
  }
}


function fetchProductByBarcodeAPI(payload) {
  const storeId = localStorage.getItem("storeId");
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const { page , filter } = payload;
  const apiURL = `${API_URL}/client/inventory/barcodeid`;
  const products = axios
    .get(apiURL, {
       params: {
        storeId: storeId,
        barcodeId: payload,
      },
      headers: headers
    })
    .then((response) => response);
  return products;
}
export function* fetchProductByBarcode(action) {
  try {
      const { payload } = action;
      const response = yield call(fetchProductByBarcodeAPI, payload);
      yield put(setProductByBarcode(response.data.data));
  } catch (error) {
    //yield put(setReponseStatus(error.response.status));
  }
}


async function fetchGlassesDropdownAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = process.env.REACT_APP_API_URL + `/v1/eyeplay/client/brand/getv1`;
  const products = axios
    .get(apiURL, {
      headers: headers,
    })
    .then((response) => response.data.data);
  return products;
}

export function* fetchGlassesDropdown(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchGlassesDropdownAPI, payload);
    yield put(setGlassDropDowns(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchBrandDropdownAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/brand/fetch`;
  const products = axios
    .get(apiURL, {
      params: {
        filter: {}
      },
      headers: headers,
    })
    .then((response) => response.data.data);
  return products;
}

export function* fetchBrandDropdown(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchBrandDropdownAPI, payload);
    yield put(setBrandDropDowns(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* librarySaga() {
  yield takeLatest(getProductListData.type, fetchProductsData);
  yield takeLatest(getProductByBarcode.type, fetchProductByBarcode);
  yield takeLatest(getGlassDropDowns.type, fetchGlassesDropdown);
  yield takeLatest(getBrandDropDowns.type, fetchBrandDropdown);
}
 