import { Typography } from "@mui/material";
import { React, useState } from "react";
import { ExpandLensIcon } from "../svg";
import { CatalogTitle, useStyles, ListHeader } from "./styles";
import { ImageNotFound } from "../../components/svg/ImageNotFound";

import DeleteIcon from "@mui/icons-material/Delete";

const Card = (props) => {
  const classes = useStyles();
  const {
    lists,
    exchange,
    width: { width },
    isCatalog,
    isDemo,
  } = props;

  let imageUrl = "";

  const [notLoaded, setNotLoaded] = useState(false);

  const onError = () => {
    setNotLoaded(true);
  }

  if (isCatalog) {
    imageUrl = lists?.lens[0].thumbnailImage
      ? lists?.lens[0].thumbnailImage
      : "https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y2FtZXJhJTIwZm9jdXN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60";
  } else if (exchange) {
    imageUrl =
      lists?.ProductImages && lists?.ProductImages[0]
        ? lists?.ProductImages[0]
        : "";
  } else if (isDemo) {
    imageUrl =
      lists?.ProductImages && lists?.ProductImages[0]
        ? lists?.ProductImages[0]
        : "";
  } else {
    imageUrl =
      "https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y2FtZXJhJTIwZm9jdXN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60";
  }

  return (
    <div className={isCatalog ? classes.catalogCard : classes.listCard}>
      <div
        className={
          isCatalog
            ? classes.catalogImgDiv
            : exchange
            ? classes.listExchange
            : classes.listImgDiv
        }
      >
        {
          notLoaded ? <ImageNotFound/> : 
          <img
            src={
              isCatalog
                ? lists?.lens[0].thumbnailImage
                  ? lists?.lens[0].thumbnailImage
                  : "https://oicappsv3-dev.s3.ap-south-1.amazonaws.com/eyeplay/lenscatalog/delete12.jpg"
                : exchange
                ? lists?.ProductImages && lists?.ProductImages[0]
                  ? lists?.ProductImages[0]
                  : ""
                : isDemo
                ? lists?.ProductImages && lists?.ProductImages[0]
                  ? lists?.ProductImages[0]
                  : ""
                : lists?.variants[0].ProductImages[0] ??
                  "https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y2FtZXJhJTIwZm9jdXN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
            }
            alt="specs"
            onError={onError}
            width={width}
            className={isCatalog ? classes.catalogImg : classes.listImg}
          />
        }
        {isCatalog && (
          <div
            className={classes.catalogExpandIcon}
            onClick={(event) =>
              props.expandImage(
                event,
                lists?.lens[0]?.features,
                lists?.lens?.[0].sellPrice,
                lists?.lens[0].thumbnailImage
              )
            }
          >
            <ExpandLensIcon />
          </div>
        )}
        {exchange && (
          <div
            className={classes.deleteIcon}
            onClick={(event) => props?.handleDelete(event, props?.index)}
          >
            <DeleteIcon />
          </div>
        )}
      </div>
      {props.isCatalog ? (
        <div className={classes.catalogContent}>
          <CatalogTitle>
            {lists?.lens[0]?.brand} - {lists?.lens[0]?.lensCoating} 
          </CatalogTitle>
        </div>
      ) : (
        <>
          <div className={classes.listContent}>
            <ListHeader>
              {exchange
                ? lists?.Brand
                : isDemo
                ? lists?.Brand
                : lists?._id.Brand ?? ""}
            </ListHeader>
            <ListHeader>
              {exchange
                ? lists?.ModelNo
                : isDemo
                ? lists?.ModelNo
                : lists?._id.ModelNo ?? ""}
            </ListHeader>
            <Typography
              sx={{ fontSize: "12px", color: "#2b2b2b", p: "0px 8px" }}
            >
              {exchange
                ? lists?.ColorCode
                : isDemo
                ? lists?.ColorCode
                : lists?.variants[0].ColorCode ?? ""}
            </Typography>
          </div>

          <div className={classes.listContent}>
            <Typography sx={{ color: "#2b2b2b", fontWeight: 600 }}>
              &#8377;
              {exchange
                ? lists?.SellPrice
                : isDemo
                ? lists?.SellPrice
                : lists?.variants?.[0].SellPrice}
            </Typography>
          </div>
          {
            exchange ? <div className={classes.listContent}>
            <Typography sx={{ color: "#03a5a5", fontWeight: 600 }}>
              {lists?.barcodeId}
            </Typography>
          </div> : "" }
        </>
      )}
    </div>
  );
};

export default Card;
