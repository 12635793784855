import {
  Dialog,
  Grid,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ContinueButton, Footer } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getSaveExchange } from "../../app/reducers/exchangeSlice";
import { useStyles } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { Card } from "../../components/Card";
import { TickIcon } from "../../components/svg/TickIcon";
import {
  getInventoryWithBarecode,
  removeInventory,
} from "../../app/reducers/exchangeSlice";
import _ from "lodash";
import {
  BarcodeScanner,
  enableBarcodeScanner,
  disableBarcodeScanner,
  setHistoryInfo,
} from "react-usb-barcode-scanner";
import config from "./config";

function NewExchange(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  const [openExchange, setOpenExchange] = useState(false);
  const [exchangeDropdown, setExchangeDropdown] = useState("");
  const [exchangeComments, setExchangeComments] = useState("");
  const [success, setSuccess] = useState(false);

  const [openScanner,setOpenScanner] = useState(false);

  const [barcode, setBarcode] = useState("");
  const [enteredBarcode, setEnteredBarcode] = useState("");  
  const [usedBarcodesEntered, setUsedBarcodesEntered] = useState([]);

  const barcodeUsb = useSelector(
    (state) => state.exchangeReducer.barcodeUsb
  );

  const inventories = useSelector((state) => state.exchangeReducer.inventories);

  const savedExchange = useSelector(
    (state) => state.exchangeReducer.savedExchange
  );


  const cardStyles = {
    width: "160px",
  };
  const exchangeReasons = ["Store Last Piece", "Broken", "Damaged", "Not the required One"];
  const handleContinueButton = () => {
    setOpenExchange(true);
  };

  const handleClose = () => {
    setOpenExchange(false);
  };

  const handleDropDownChange = (newValue) => {
    setExchangeDropdown(newValue);
  };

  const handleExchangeComments = (event) => {
    setExchangeComments(event.target.value);
  };

  const handleConfirmExchange = () => {
    const storeId = localStorage.getItem("storeId");
    let temp = [];
    inventories?.map((data) => {
      temp.push({ barcodeId: data?.barcodeId });
    });
    const obj = {
      inventories: temp,
      reason: exchangeDropdown,
      storeId: storeId,
      comments: exchangeComments,
    };
    setOpenExchange(false);
    dispatch(getSaveExchange(obj));
  };

  console.log("inventories", inventories)

  const handleDelete = (index, id) => {
    const temp = usedBarcodesEntered.filter((item) => item !== id);
    setUsedBarcodesEntered(temp);
    dispatch(removeInventory(index));
  };

  const handleChange = (e) => {
    // setBarcode(e.target.value);
    setEnteredBarcode(e.target.value);
  };

  const handleBarcode = () => {
    console.log("Inside", usedBarcodesEntered);

    if (!usedBarcodesEntered.includes(enteredBarcode)) {
      const usedBarcodesEnteredLocal = [...usedBarcodesEntered];
      usedBarcodesEnteredLocal.push(enteredBarcode);
      setUsedBarcodesEntered(usedBarcodesEnteredLocal);
      dispatch(getInventoryWithBarecode(enteredBarcode));
    }
    // setOpenBarcode(false);
    // dispatch(getInventoryWithBarecode(barcode));
  };

  const backToExchange = () => {
    props.backToExchange();
  };

  const handleScan = () => {
    setOpenScanner(true);
  }
  
  useEffect(() => {
    if (!_.isEmpty(savedExchange)) {
      setSuccess(true);
      history("/exchange");
    }
  }, [inventories, savedExchange]);

  useEffect(() => {
    if (!_.isEmpty(barcodeUsb) && openScanner) {
      if (!usedBarcodesEntered.includes(barcodeUsb)) {
        const usedBarcodesEnteredLocal = [...usedBarcodesEntered];
        usedBarcodesEnteredLocal.push(barcodeUsb);
        setUsedBarcodesEntered(usedBarcodesEnteredLocal);
        dispatch(getInventoryWithBarecode(barcodeUsb));
      }
    }
  }, [barcodeUsb]);

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "25px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              type="text"
              placeholder="Enter Barcode"
              variant="outlined"
              size="small"
              onChange={(event) => handleChange(event)}
              sx={{ borderRadius: "35px" }}
            />
            <ContinueButton onClick={handleBarcode}>Add</ContinueButton>
          </div>
          <ContinueButton onClick={handleScan}>{ openScanner ? "Scanner On" : "Scan Products"}</ContinueButton>
        </div>
        {  openScanner ? <BarcodeScanner config={config} /> : ""}
        <div>
          <Grid container spacing={2}>
            {inventories?.map((product, index) => (
              <Grid item xs={12} sm={4} md={4} key={index}>
                <div>
                  <Card
                    lists={product}
                    width={cardStyles}
                    isCatalog={false}
                    exchange={true}
                    handleDelete={(event, index) => handleDelete(index,product.barcodeId)}
                    index={index}
                  />
                  
                </div>
              </Grid>
            ))}
            {_.isEmpty(inventories) ? (
              <Typography sx={{ marginLeft: "50px" }}>
                No Data Found
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Footer
            disabled={ _.isEmpty(inventories)}
            handleContinueButton={handleContinueButton}
            onContinue={"Submit"}
          ></Footer>
        </div>
        {success ? (
          <div>
            <Dialog
              open={success}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ borderRadius: "8px" }}
            >
              <div style={{ padding: "20px 60px 40px" }}>
                <div style={{ textAlign: "center" }}>
                  <div style={{ paddingLeft: "25px" }}>
                    <TickIcon />
                  </div>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, mb: 3 }}
                  >
                    Exchanges Placed Successfully
                  </Typography>
                  <ContinueButton onClick={backToExchange}>
                    Back To Exchanges
                  </ContinueButton>
                </div>
              </div>
            </Dialog>
          </div>
        ) : (
          " "
        )}
        <Dialog
          fullWidth="false"
          open={openExchange}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ borderRadius: "8px", width: "100%" }}
        >
          <DialogTitle>
            New Exchange
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div>
              <div style={{ paddingBottom: "20px" }}>
                <Typography>Reason</Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={exchangeReasons}
                  sx={{ width: "70%" }}
                  onChange={(event, newValue) => {
                    handleDropDownChange(newValue);
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      className={classes.outline}
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
              <div style={{ paddingBottom: "20px" }}>
                <Typography>Comments</Typography>
                <TextField
                  size="small"
                  multiline
                  rows={4}
                  helperText="Comments are Mandatory"
                  error={_.isEmpty(exchangeComments)}
                  onChange={handleExchangeComments}
                  placeholder="Type Here..."
                  sx={{ width: "70%" }}
                />
              </div>
              <div>
                <ContinueButton onClick={handleConfirmExchange}>
                  Confirm
                </ContinueButton>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default NewExchange;
